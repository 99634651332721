import React, { useState } from 'react';
import Helmet from 'react-helmet';
import {
  Box,
  TextField,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { navigate } from '@reach/router';
import {
  GoogleMap,
  Data,
  useLoadScript,
  Marker,
  StandaloneSearchBox,
} from '@react-google-maps/api';
import GoogleMapsApiInfo from '../../config/config';
import { googleMapsLibraries } from '../../lib/gMaosConfig';
import serviceTerritory from '../../components/outage/outage-center-map/service_territory.json';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mapContainer: {
      padding: '8px',
      position: 'absolute',
      top: '10px',
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '90%',
      zIndex: 4,
      background: 'white',
      borderRadius: '10px',
      boxShadow: '-1px 3px 10px grey',
    },
  }),
);

function PGEServiceAreaMap() {
  const isBrowser = !(typeof window === 'undefined');
  const isNotAnIframe = isBrowser && window.parent.location === window.location;
  isNotAnIframe && navigate('/');

  const [map, setMap] = useState<google.maps.Map<Element>>();
  const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox>();
  const [markerPosition, setMarkerPosition] = useState<
    google.maps.LatLng | google.maps.LatLngLiteral
  >();
  const classes = useStyles();
  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: GoogleMapsApiInfo?.firebase.key,
    libraries: googleMapsLibraries,
  });

  const handleMapLoad = (ref: google.maps.Map<Element>) => {
    setMap(ref);
  };
  const mapCenter = { lat: 45.345, lng: -122.62 };
  const mapContainerStyle = {
    width: '100vw',
    height: '100vh',
  };
  const mapZoom = isBrowser && window && window.innerWidth < 400 ? 7 : 8;
  const mapDataOptions = {
    style: {
      fillColor: '#27788C',
      fillOpacity: 0.1,
      strokeColor: '#27788C',
      strokeWeight: 1,
    },
  };
  const mapDataOnLoad = (data: google.maps.Data) => {
    data.addGeoJson({
      type: 'Feature',
      geometry: serviceTerritory,
    });
  };
  const standaloneSearchBoxBounds = {
    north: mapCenter.lat + 1,
    south: mapCenter.lat - 1,
    east: mapCenter.lng + 1,
    west: mapCenter.lng - 1,
  };
  const handleStandaloneSearchBoxOnLoad = (
    ref: google.maps.places.SearchBox,
  ) => {
    setSearchBox(ref);
  };
  const handlePlacesChanged = () => {
    if (Number(searchBox?.getPlaces()?.length) !== 1) {
      return;
    }
    const location = searchBox?.getPlaces()[0]?.geometry?.location;
    const lat = location?.lat() || mapCenter.lat;
    const lng = location?.lng() || mapCenter.lng;
    setMarkerPosition({
      lat,
      lng,
    });
    const bounds = new google.maps.LatLngBounds();
    bounds.extend(mapCenter) && location && bounds.extend(location);
    map?.fitBounds(bounds, {
      bottom: 0,
      left: 0,
      right: 0,
      top: 30,
    });
    Number(map?.getZoom()) > mapZoom && map?.setZoom(mapZoom);
  };

  return isLoaded ? (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <GoogleMap
        onLoad={handleMapLoad}
        mapContainerStyle={mapContainerStyle}
        center={mapCenter}
        zoom={mapZoom}
        options={{
          panControl: false,
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
        }}
      >
        <Data options={mapDataOptions} onLoad={mapDataOnLoad} />
        <StandaloneSearchBox
          onLoad={handleStandaloneSearchBoxOnLoad}
          onPlacesChanged={handlePlacesChanged}
          bounds={standaloneSearchBoxBounds}
        >
          <Box className={classes.mapContainer}>
            <TextField
              fullWidth
              placeholder="Search for an address..."
              InputProps={{ disableUnderline: true }}
            />
          </Box>
        </StandaloneSearchBox>
        <Marker
          position={markerPosition || mapCenter}
          visible={Boolean(markerPosition)}
        />
      </GoogleMap>
    </>
  ) : (
    <></>
  );
}

export default React.memo(PGEServiceAreaMap);
